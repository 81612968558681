import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/*<img src={logo} className="App-logo" alt="logo" />*/}
        <p>
          Checkita is an open-source data quality framework bundled with end-to-end no-code applications
          for batch and streaming data
        </p>
        <a
            className="App-link"
            href="https://github.com/Raiffeisen-DGTL/checkita-data-quality"
            target="_blank"
            rel="noopener noreferrer">
          Github
        </a>
        <a
            className="App-link"
            href="https://raiffeisen-dgtl.github.io/checkita-data-quality/"
            target="_blank"
            rel="noopener noreferrer">
          Documentation
        </a>
        <a
            className="App-link"
            href="https://discord.gg/rp69aK6W"
            target="_blank"
            rel="noopener noreferrer">
          Discord
        </a>
      </header>
    </div>
  );
}

export default App;
